import React, { useEffect, useState } from 'react';

import {
  Title,
  Dash,
  Headers,
  Footer,
  Projetos,
  Text,
  Section,
  Foto,
  ProjectContent,
  TextContent,
  Img,
  Acessar,
  Description,
  Loader,
  TextTwrite,
} from './styles';
import ReactTyping from 'reactjs-typing-effect';

import { FiMail } from 'react-icons/fi';
import { FaAngleDoubleRight, FaCaretRight } from 'react-icons/fa';

import apiimg from './../../assets/images/api.svg';
import web from './../../assets/images/web.svg';
import mobile from './../../assets/images/mobile.svg';
import study from './../../assets/images/study.svg';
import defaultSvg from './../../assets/images/default.svg';
import ImagePerfil from './../../assets/images/fotoperfil.jpg';

import api from '../../shared/http/api';

import ExpiredStorage from 'expired-storage';

interface Projects {
  id: string;
  name: string;
  description: string;
  html_url: string;
  img?: string;
  languages_url: string;
  languages: string[];
}

let languages = [
  { name: 'css', value: 'css3' },
  { name: 'html', value: 'html5' },
  { name: 'c', value: 'c' },
  { name: 'python', value: 'python' },
  { name: 'javascript', value: 'javascript' },
  { name: 'object-c', value: 'objectc' },
  { name: 'nodejs', value: 'nodejs' },
  { name: 'typescript', value: 'typescript' },
  { name: 'ruby', value: 'ruby' },
  { name: 'java', value: 'java' },
];

const img = [
  { name: 'api', value: apiimg },
  { name: 'mobile', value: mobile },
  { name: 'study', value: study },
  { name: 'web', value: web },
];

const listTyping = [
  'Nodejs',
  'Reactjs',
  'React Native',
  `Swagger`,
  'ChatBot',
  'Freelancer',
  'Desenvolvedor Fullstack',
  'GraphQL',
  'Prisma',
  'Adonisjs',
  'Express',
];

const Dashboard: React.FC = () => {
  const [projects, setProjects] = useState<Projects[]>([] as Projects[]);
  const [loading, setLoading] = useState(false);
  const expiredStorage = new ExpiredStorage();

  useEffect(() => {
    async function loadProjects() {
      if (expiredStorage.getJson('repos')) {
        setProjects(expiredStorage.getJson('repos'));
        return;
      }
      const dataProjects: Projects[] = [];
      setLoading(true);
      const { data } = await api.get('repos');
      for (const { id, description, html_url, languages_url, name } of data) {
        const languages = await getLanguages(languages_url);
        const nameImg = name.substring(0, name.indexOf('-')).toLowerCase();
        let urlImg = defaultSvg;
        img.forEach(imagem => {
          if (imagem.name === nameImg) {
            urlImg = imagem.value;
          }
        });

        dataProjects.push({
          id,
          languages,
          description,
          html_url,
          languages_url,
          name,
          img: urlImg,
        });
      }

      setProjects(dataProjects);
      expiredStorage.clearExpired();
      expiredStorage.setJson('repos', dataProjects, 172800);
      setLoading(false);
    }

    loadProjects();
  }, [expiredStorage]);

  async function getLanguages(url: string) {
    const data = await api.get(url);
    const response: string[] = [];

    Object.keys(data.data).forEach(data => {
      languages.forEach(language => {
        if (language.name === data.toLowerCase()) {
          data = language.value;
          response.push(data);
        }
      });
    });
    return response;
  }

  return (
    <>
      <Headers>
        <div id="logo">
          <div className="border"></div>
          <div>
            <h1>C</h1>
            <h1>S</h1>
          </div>
        </div>

        <div>
          <FiMail color="rgba(255,255,255,0.9)" />
          <h2>
            <a href="mailto: contato@clebersilva.online">
              contato@clebersilva.online
            </a>
          </h2>
        </div>
      </Headers>
      <Dash>
        <Section>
          <TextTwrite>
            <h1>Cleber Silva</h1>
            <p>Programador Javascript</p>
            <p>Criação de API's</p>
            <div>
              <FaAngleDoubleRight color="rgba(255,255,255,0.9)" />
              <p>
                <ReactTyping
                  key="ReactTyping"
                  StringList={listTyping}
                  speed={1000}
                  eraseSpeed={1000}
                />
              </p>
            </div>
          </TextTwrite>
          <Foto>
            <img src={ImagePerfil} alt="Perfil" />
          </Foto>
        </Section>
        <Title>
          <h1>Sobre</h1>
        </Title>
        <Text>
          Explorador do mundo tecnológico. Ser desenvolvedor é a realização de
          um sonho, que impulsionado pela curiosidade, obtenho novos
          conhecimentos todos os dias.
          <br /> Compromisso e dedicação em todos os projetos que participo.
          Assim foco em gerar o maior valor com o menor custo.
        </Text>
        <Loader visible={loading} type={'BallTriangle'} color="#fca05d" />

        {!loading && (
          <>
            <Title>
              <h1>Portfólio</h1>
            </Title>
            <Projetos>
              {projects?.map(project => (
                <ProjectContent
                  key={project.id}
                  onClick={() => {
                    window.open(project.html_url, '_blank');
                  }}
                >
                  <Img>
                    <img src={project.img} alt={project.name} />
                  </Img>
                  <TextContent>
                    <h2>{project.name}</h2>
                    <Description>{project.description}</Description>

                    <div className="icons">
                      {project.languages.map(language => (
                        <img
                          key={language}
                          src={`https://raw.githubusercontent.com/devicons/devicon/9c6bfdb9783cdfe1018666ed76adcfd3eab6fad6/icons/${language
                            .toLowerCase()
                            .replace('-', '')}/${language
                            .toLowerCase()
                            .replace('-', '')}-original.svg`}
                          width="30px"
                          height="30px"
                          alt={language}
                        />
                      ))}
                    </div>
                  </TextContent>
                  <Acessar>
                    <FaCaretRight />
                  </Acessar>
                </ProjectContent>
              ))}
            </Projetos>
          </>
        )}
      </Dash>
      <Footer>
        <h1>Desenvolvedor Cleber Silva &#174; 2021</h1>
        <div>
          <a target="_black" href="https://www.linkedin.com/in/cleberaugustz/">
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/c5378d6c2510ffa0b3e4475af95618a8048d6cf1/icons/linkedin/linkedin-original.svg"
              alt="Linkedin"
            />
          </a>
        </div>
        <div>
          <a target="_black" href="https://github.com/CleberAugustz">
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/c5378d6c2510ffa0b3e4475af95618a8048d6cf1/icons/github/github-original.svg"
              alt="GitHub"
            />
          </a>
        </div>
      </Footer>
    </>
  );
};

export default Dashboard;

import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: #fff;
    font-weight: 400;

    &:hover {
      color: #f3f3f3;
    }
  }

  body {
    background: black;
    z-index:1;
    -webkit-font-smoothing: antialiased;

  }

  body, input, button {
    font: 16px Roboto, sans-serif;
  }

  button {
    cursor: pointer;
  }


`;

export const VideoPlay = styled.div`
  position: fixed;
  left: 0;
  margin-top: 40px;
  z-index: -1;
  width: 100%;
  height: 100%;
`;
export const Video = styled.video`
  position: fixed;
  z-index: -1;
  left: 0;

  @media (min-width: 780px) {
    & {
      display: flex;
      width: 100%;
    }
    & source {
      min-width: 100%;
    }
  }
`;

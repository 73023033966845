import styled from 'styled-components';
import spinner from 'react-loader-spinner';

export const Headers = styled.header`
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  padding: 5px 60px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: sticky;
  width: 100%;
  top: 0;

  background: rgba(0, 0, 0, 0.8);

  @media (max-width: 780px) {
    & {
      padding: 20px;
      flex-direction: column;
    }
  }

  align-items: center;
  font-size: 25px;

  h1 {
    margin-left: 3px;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    color: #fff;
  }

  h2 {
    margin-left: 3px;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Noto Sans JP', sans-serif;
    color: #fff;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      padding-right: 5px;
      width: 30px;
      height: 30px;
    }
  }

  div#logo {
    background-color: black;
    width: 45px;
    height: 45px;
    border-radius: 50%;

    div.border {
      position: absolute;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 5px white double;
      animation: rotate 3.5s linear infinite;
    }

    div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h1 {
      @keyframes blurIntro {
        0% {
          filter: brightness(100%);
        }
        50% {
          filter: brightness(120%);
        }
        100% {
          filter: brightness(100%);
        }
      }
      position: relative;
      text-align: center;
      align-items: center;
      margin: 0;
      color: #fca05d;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 26px;
      animation: blurIntro 5s infinite;
      & + h1 {
        position: relative;
        left: -1px;
      }
    }
  }
`;

export const TextTwrite = styled.div`
  h1 {
    font-size: 50px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: #fff;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Noto Sans JP', sans-serif;
    color: #fca05d;
    margin-left: 5px;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Title = styled.div`
  display: flex;
  margin-bottom: 15px;
  h1 {
    font-size: 35px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #fff;

    border-bottom: 5px solid #fca05d;
  }
`;

export const Dash = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.8);
  padding: 0 30px;
`;

export const Loader = styled(spinner)`
  justify-content: center;
  display: flex;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px;
  margin: 0 0 20px 0;

  @media (max-width: 780px) {
    & {
      flex-direction: column;
    }
  }
`;

export const Text = styled.p`
  font-size: 18px;
  color: #fff;

  line-height: 1.7m;
  font-weight: 300;
  text-align: justify;
  font-family: 'Noto Sans JP', sans-serif;

  margin-bottom: 30px;
`;

export const Foto = styled.div`
  img {
    width: 100%;
    max-width: 250px;
    height: 100%;
    max-height: 230px;
    border-radius: 5px;
  }
`;

export const Skills = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 63px;

  h1 {
    font-size: 26px;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const Footer = styled.section`
  margin-top: 10px;
  padding: 5px 10px;
  width: 100%;
  height: 43px;
  background: #fca05d;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: sticky;
  bottom: 0;

  h1 {
    background: #fca05d;
    font-weight: 300;
    font-size: 18px;
  }
  a {
    decoration: none;
    cursor: pointer;

    img {
      width: 26px;
      margin-left: 5px;
      &:hover {
        color: blue;
      }
    }
  }
`;

//// Projects

export const Img = styled.section`
  display: flex;
  justify-self: center;
  padding: 5px 0;

  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    max-height: 200px;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  word-wrap: normal;
  line-break: auto;
  max-width: 50%;
  min-width: 50%;
  h2 {
    font-weight: 600;
    font-family: 'Signika Negative', sans-serif;
    color: black;
    font-size: 18px;
  }
  @media (max-width: 780px) {
    & {
      max-width: 80%;
      min-width: 80%;
    }
  }
`;

export const Description = styled.p`
  font-size: 14px;

  font-weight: 300;
  letter-spacing: 1px;
  font-family: 'Noto Sans JP', sans-serif;
  text-align: justify;
`;

export const Projetos = styled.div`
  width: 100%;
  max-width: 800px;
  max-height: 500px;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;
  align-items: center;
  overflow: auto;
  overflow-x: hidden;

  h1 {
    font-size: 26px;
    color: white;
  }
`;

export const ProjectContent = styled.div`
  height: auto;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px;
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: 750px;
  width: 100%;

  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  @media (max-width: 780px) {
    & {
      flex-direction: column;
    }
  }

  & + & {
    margin-top: 10px;
  }
  &:hover {
    background: #fca05d;
    transition: 500ms;
    animation: none;
    transform: scale(1.05);
  }

  div.icons {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    img + img {
      margin-left: 5px;
    }
  }
`;

export const Acessar = styled.button`
  text-decoration: none;
  animation: none;
  background: none;
  outline: none;
  border: none;

  font-size: 30px;
  color: black;
  @media (max-width: 780px) {
    & {
      display: none;
    }
  }
`;

import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.github.com/users/CleberSilva93/',
  headers: {
    Accept: 'application/vnd.github.v3.text-match+json',
    Authorization: `Bearer ${process.env.REACT_APP_GIT_TOKEN_ACCESS}`,
  },
});

export default api;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle, { Video } from './global';
import video from './assets/video/computer.mp4';

import Routes from './routes';

const App: React.FC = () => (
  <>
    <GlobalStyle />
    <Video loop autoPlay muted width="auto">
      <source src={video} type="video/mp4"></source>
    </Video>

    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </>
);

export default App;
